import React from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";

import Blog from "../../assets/json/blog.json";

const articleData = {
    id: 3,
    title: "Virtual Learning",
    desc:
        "Ci troviamo davanti a tecnologie sempre più utilizzate, in grado di rivoluzionare l’insegnamento e lo studio a distanza.",
    img: "/blog-image/virtual_reality.jpg",
    imgs: [
        "/blog-image/react_native.png",
        "/blog-image/app.png",
        "/blog-image/app2.png",
    ],
    page: "blog/virtual_learning",
    data: "19 Ott 2022",
    author: "Staff",
};

const BlogDetails = () => (
    <Layout>
        <SEO
            title={articleData.title}
            meta={[
                {
                    name: `description`,
                    content:
                        "Negli ultimi tempi, a causa della Pandemia da Covid-19, l’insegnamento e l’apprendimento a distanza sono state indicati come soluzioni più sicure.",
                },
                {
                    property: `og:title`,
                    content: articleData.title,
                },
            ]}
        />

        <Navbar />

        <PageBanner pageTitle={articleData.title} />

        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img
                                    src={
                                        require("../../assets/images" +
                                            articleData.img).default
                                    }
                                    alt="image"
                                />
                            </div>

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Icon.Clock /> {articleData.data}
                                        </li>
                                        <li>
                                            <Icon.User />{" "}
                                            <Link to="#">
                                                {articleData.author}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <p>
                                    Negli ultimi tempi, a causa della Pandemia
                                    da Covid-19, l’insegnamento e
                                    l’apprendimento a distanza sono state
                                    indicati come soluzioni più sicure.
                                    <br />
                                    Ad oggi però, cosa ne è rimasto e come il
                                    Virtual Learning promette di influenzare il
                                    futuro dell’Educazione, anche con il ritorno
                                    in presenza?
                                </p>
                                <p>
                                    Favorevoli o contrari poco importa. Ci
                                    troviamo davanti a tecnologie sempre più
                                    utilizzate, come la Realtà Virtuale e la
                                    Realtà Aumentata, in grado di creare nuovi
                                    spazi virtuali e rivoluzionare
                                    l’insegnamento e lo studio a distanza. Da
                                    qui, la domanda sorge spontanea: come sarà
                                    la Scuola del futuro? Oltre a riportare un
                                    ambiente fisico, il Virtual Learning ti
                                    permette di vivere esperienze immersive,
                                    come ad esempio un modo tutto nuovo di
                                    assistere e provare simulazioni legate alle
                                    materie scolastiche.
                                </p>
                                <p>
                                    Volendo spezzare una lancia a favore del
                                    “ritorno alla normalità” dopo quasi tre anni
                                    di distanze sociali, cominciamo ad
                                    analizzare il Virtual Learning secondo
                                    quelli che vengono indicati come lati
                                    negativi.
                                </p>
                                <p>
                                    Innanzitutto, a prescindere dall’eta degli
                                    studenti, il mondo accademico è sinonimo di
                                    stress. Lo stress, infatti, influisce sulla
                                    performance complessiva dello studente
                                    andando ad impattare, in alcuni casi, la
                                    salute fisica e mentale. Le distanze
                                    obbligate dalla Pandemia in ambito
                                    scolastico hanno, per moltissime persone,
                                    accentuato questa problematica, con
                                    conseguenze rilevanti sui percorsi
                                    scolastici degli alunni. Non solo, con
                                    l’avvento della didattica a distanza le
                                    disparità tra le famiglie si sono fatte più
                                    marcate: in molte case la connessione ad
                                    internet è tutt’altro che scontata, così
                                    come la presenza di un computer o di un
                                    tablet da cui seguire le lezioni. Sono state
                                    fatte numerose ricerche sul legame tra
                                    didattica a distanza e peggioramento della
                                    salute mentale degli alunni. Il risultato,
                                    in molti casi, è stato che più del 50% degli
                                    studenti mostrava un rischio moderato o
                                    acuto di Disturbi d’Ansia. Indicando,
                                    inoltre, che per gli alunni più piccoli il
                                    rischio aumentava. Le distrazioni sono un
                                    altro elemento importante quando si valutano
                                    gli effetti negativi del Virtual Learning.
                                    L’ambiente circostante gioca un ruolo
                                    fondamentale nell’attenzione dello studente.
                                    Inoltre, in molti casi, viene segnalata una
                                    mancanza di struttura nell’insegnamento a
                                    distanza. Da un giorno all’altro, migliaia e
                                    migliaia di insegnanti si sono trovati per
                                    la prima volta ad affrontare una nuova
                                    modalità di insegnamento, con tutte le
                                    limitazioni indicate sopra. Provare ad
                                    adattare un metodo consolidato ad una nuova
                                    realtà che modifica l’interazione e la
                                    ricezione di feedback risulterebbe difficile
                                    a molti.
                                </p>

                                <p>
                                    Gli aspetti negativi, specie se si guarda al
                                    benessere psico-fisico degli studenti, sono
                                    estremamente rilevanti. Tuttavia, sarebbe
                                    poco saggio abbandonare l’idea di un Virtual
                                    Learning funzionale e ricco di potenzialità.
                                    Le opportunità sono numerose, le occasioni
                                    di apprendimento fuori dagli schemi fanno da
                                    pilastri a questo fenomeno. Quello che si
                                    può fare, soprattutto per Aziende che come
                                    noi lavorano nel settore Tech, tra l’altro
                                    lavorando molto spesso anche con diverse
                                    Scuole - In Appius, ad esempio, abbiamo
                                    sviluppato un gestionale, Appius Academy,
                                    appositamente per Scuole ed Accademie,
                                    volendo offrire una soluzione software che
                                    guardasse alle esigenze di organizzazione
                                    dei corsi sia in presenza che online e che,
                                    nel complesso, tenga conto della
                                    digitalizzazione e del rapporto con la
                                    tecnologia di alunni ed insegnanti - è
                                    esaminare i vantaggi e gli svantaggi per
                                    offrire tutti i tool necessari per
                                    affiancare tecnologicamente il cambiamento.
                                    Così da garantire il benessere degli
                                    studenti e degli insegnanti, guardando
                                    sempre al futuro e all’innovazione.
                                </p>

                                <img
                                    src={virtual_lesson}
                                    style={{ marginBottom: 40, marginTop: 30 }}
                                    alt="Multiverso"
                                />
                                <br />

                                <p>
                                    Con le lezioni online, specialmente per chi
                                    lavora, si ha maggiore flessibilità.
                                    Permette un risparmio di tempo - elemento
                                    non affatto scontato se si pensa che la
                                    maggior parte dei ragazzi, dopo scuola, è
                                    iscritto a società sportive o, giustamente e
                                    fortunatamente, ha il tempo di dedicarsi a
                                    nuovi hobby e riposare - ed abbattere i
                                    costi di trasporto, oltre che sollevare
                                    genitori e famigliari dai problemi relativi
                                    al bilanciamento tra orari lavorativi
                                    personali e orari di chiusura e apertura
                                    delle scuole. Inoltre, grazie agli strumenti
                                    del Virtual Learning, l’apprendimento viene
                                    spostato su un piano estremamente immersivo,
                                    ottenendo risultati che con il metodo
                                    tradizionale non si avranno quasi mai. I
                                    tool permettono agli studenti di entrare
                                    letteralmente dentro a quello che stanno
                                    studiando, rendendo dinamico e molto più
                                    interessante lo studio.
                                </p>
                                <p>
                                    La Realtà Virtuale e la Realtà Aumentata
                                    sono sicuramente alcuni degli strumenti che
                                    più si legano al Virtual Learning pensato
                                    per la Scuola del futuro. Entrambe le
                                    tecnologie nascono per il mondo dei
                                    videogiochi e dell’intrattenimento, ma
                                    trovano oggi largo spazio in ambiti come
                                    l’Education. Per quanto riguarda la Realtà
                                    Aumentata i tool più comuni passano dai
                                    comuni smartphone ad apparecchi sofisticati
                                    come gli Smart Glasses o bracciali Hi-Tech
                                    per il controllo da remoto di
                                    apparecchiature robotiche e elettroniche. La
                                    Realtà Aumentata va, quindi, ad impattare il
                                    campo visivo dell’utente, spesso in una zona
                                    ben precisa e limitata - davanti ai propri
                                    occhi - tramite immagini e completando il
                                    tutto con informazioni aggiuntive su
                                    smartphone o tablet. Per funzionare la
                                    Realtà Aumentata sfrutta app specifiche e
                                    dispositivi digitali (Smart Glasses,
                                    smartphone, ecc.). Se si guarda alle
                                    apparecchiature più sofisticate, come gli
                                    HoloLens di Microsoft - con visiera
                                    semi-trasparente ti permette di vedere
                                    l'ambiente circostante dove vengono
                                    proiettatI gli ologrammi - è facile intuire
                                    come il contesto scolastico possa diventare,
                                    in poco tempo, maggiormente dinamico e
                                    affascinante per gli studenti. Non solo per
                                    le esperienze di apprendimento uniche, ma
                                    anche per l’avvicinamento dei più piccoli
                                    alle nuove tecnologie. Molte di queste, tra
                                    l’altro, potrebbero essere già utilizzate
                                    dagli stessi per i videogames - esempio
                                    lampante le app di Augmented Reality in
                                    grado di interfacciarsi con l’ambiente
                                    circostante ed aggiungervi informazioni - di
                                    conseguenza, perché non implementarle anche
                                    a scuola?
                                </p>

                                <p>
                                    La Realtà Virtuale o Virtual Reality,
                                    invece, necessità di strumenti in grado di
                                    “eliminare” il contatto con ciò che ci
                                    circonda: il campo visivo muta facendoti
                                    immergere completamente in una nuova realtà.
                                    Per la VR troviamo dispositivi come l’Oculus
                                    Rift e HTC Vive. Imparare in una realtà
                                    distaccata da quella reale sembra quasi
                                    fantascienza, invece è realtà.
                                </p>

                                <img
                                    src={vr}
                                    style={{ marginBottom: 40, marginTop: 30 }}
                                    alt="Multiverso"
                                />
                                <br />

                                <p>
                                    Guardando all’attualità, il mondo Education
                                    - dove si registra un utilizzo maggiore di
                                    Realtà Aumentata da dispositivi mobili -
                                    sembra essere un settore perfetto per lo
                                    sviluppo di queste tecnologie. Colossi come
                                    Google hanno già iniziato ad associare
                                    esperienze di Realtà Aumentata e Realtà
                                    Virtuale alla propria offerta formativa.
                                    Quanto sarebbe interessante poter
                                    ricostruire scenari, come quelli storici,
                                    tramite VR? Quanto potrebbe essere
                                    formativo, grazie alla Realtà Aumentata,
                                    fornire maggiori informazioni su un
                                    determinato oggetto o luogo?
                                </p>
                                <p>
                                    All’inizio del testo si è fatto riferimento
                                    a come, per molti ragazzi, le
                                    apparecchiature tecnologiche in casa sono
                                    tutt’altro che scontate. Si riuscirà ad
                                    offrire nuovi strumenti di apprendimento
                                    virtuali, garantendo però un sostegno agli
                                    alunni che, al di fuori della scuola,
                                    riscontrano problematiche nell’ottenimento
                                    di dispositivi e connessioni? Le nostre
                                    scuole sono pronte a fornire formazione agli
                                    insegnanti su Realtà Virtuale e Aumentata?
                                    Il Virtual Learning ha lo scopo di
                                    facilitare l’apprendimento di concetti
                                    tramite esperienze nuove, dinamiche e ad
                                    alto valore immersivo. Lo “sforzo” di
                                    accompagnare il Virtual Learning, sempre di
                                    più, all’interno delle scuole sembra essere
                                    più che giustificato dal grande potenziale e
                                    dalle opportunità che offre agli studenti.
                                </p>
                                <p>
                                    Se sei interessato a nuove soluzioni per la
                                    tua scuola, puoi contare su Appius.
                                    Sviluppiamo piattaforme in grado di
                                    soddisfare i tuoi requisiti tecnici di
                                    gestione, senza tralasciare la cura
                                    dell’interfaccia per i tuoi utenti -
                                    studenti, insegnanti e genitori. Siamo
                                    sempre felici di ascoltare nuove proposte e
                                    soddisfare necessità in contesto scolastico.
                                    Scrivici a{" "}
                                    <a
                                        href="mailto:info@appius.it"
                                        target="_blank"
                                    >
                                        info@appius.it
                                    </a>{" "}
                                    o chiamaci allo 0536 1856617. Visita Appius
                                    Academy, il gestionale pensato per la tua
                                    Scuola o Accademia:{" "}
                                    <a
                                        className="text-decoration-none blank-link"
                                        href="https://www.appiusacademy.com"
                                        target="_blank"
                                    >
                                        www.appiuscademy.com
                                    </a>
                                </p>

                                <br />
                                <Link to="/contact" className="btn btn-primary">
                                    Contattaci
                                </Link>
                            </div>

                            <div
                                style={{ marginTop: 30 }}
                                className="startp-post-navigation"
                            >
                                {Blog.articles
                                    .filter((a) => a.id != articleData.id)
                                    .slice(0, 2)
                                    .map((article) => {
                                        return (
                                            <div className="prev-link-wrapper">
                                                <div className="info-prev-link-wrapper">
                                                    <Link
                                                        to={"/" + article.page}
                                                    >
                                                        <span className="image-prev">
                                                            <img
                                                                src={
                                                                    require("../../assets/images" +
                                                                        article.img)
                                                                        .default
                                                                }
                                                                alt="image"
                                                            />
                                                            <span className="post-nav-title">
                                                                VAI
                                                            </span>
                                                        </span>

                                                        <span className="prev-link-info-wrapper">
                                                            <span className="prev-title">
                                                                {article.title}
                                                            </span>
                                                            <span className="meta-wrapper">
                                                                <span className="date-post">
                                                                    {
                                                                        article.data
                                                                    }
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <BlogSidebar id={articleData.id} />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
);

export default BlogDetails;
